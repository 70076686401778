import { storeToRefs } from 'pinia'
import { SPORTS_URLS, PAGE_URLS, ACCOUNT_URLS } from '~/config/page-url'
import { PAGE_PARAMS } from '~/constants/common'
import { LOBBY_GAME_URLS } from '~/config/page-url'
import { useAppStore } from '~~/store/app'
import { IContact } from '~~/types/seo'

const PATH_IMAGE = '/assets/images/components/desktop/footer/'

export const getFooterNavigation = () => {
  const { $config, $pinia } = useNuxtApp()
  const store = useAppStore($pinia)
  const { contact } = storeToRefs(store)
  const result = contact?.value?.reduce((acc, item: IContact) => {
    acc[item.name.toLowerCase().replace(/\s/g, '')] = { url: item.url }
    return acc
  }, {} as Record<string, { url: string }>)

  return {
    NAVIGATIONS: [
      {
        title: 'Thể thao',
        subCategories: [
          { title: 'Lịch thi đấu', link: PAGE_URLS.SCHEDULES },
          { title: 'Cược thể thao', link: SPORTS_URLS.INDEX }
        ]
      },
      {
        title: 'Trò chơi',
        subCategories: [
          { title: 'Bắn cá', link: LOBBY_GAME_URLS.BAN_CA },
          { title: 'Slots', link: LOBBY_GAME_URLS.SLOTS_GAME },
          { title: 'Nổ hũ', link: LOBBY_GAME_URLS.NO_HU },
          { title: 'Game bài', link: LOBBY_GAME_URLS.GAME_BAI },
          { title: 'Lô đề', link: LOBBY_GAME_URLS.LO_DE },
          { title: 'Table games', link: LOBBY_GAME_URLS.TABLE_GAME },
          { title: 'Game nhanh', link: LOBBY_GAME_URLS.QUICK_GAMES }
        ]
      },
      {
        title: 'Về chúng tôi',
        subCategories: [
          { title: `Giới thiệu ${$config.public.BRANCH_NAME}`, link: `${PAGE_URLS.INTRODUCTION}` },
          { title: 'Điều kiện - Điều khoản', link: `${PAGE_URLS.POLICY}` },
          { title: 'Bảo mật', link: `${PAGE_URLS.SECURITY}` },
          { title: 'Chơi game có trách nhiệm', link: `${PAGE_URLS.RESPONSIBILITY}` },
          { title: 'Quy định chung', link: `${PAGE_URLS.RULE}` }
        ]
      },
      {
        title: 'Hỗ trợ 24/7',
        subCategories: [
          {
            title: 'Trực tuyến 24/7',
            type: 'BLANK',
            link: `${result?.livechat?.url}`
          },
          { title: 'Telegram CSKH', type: 'BLANK', link: `${result?.cskh?.url}` },
          { title: 'Hướng dẫn Đăng ký', link: `${PAGE_URLS.GUIDE_REGISTER}` }
        ]
      },
      {
        title: 'Thông tin',
        subCategories: [{ title: 'Khuyến mãi /Sự kiện', link: PAGE_URLS.PROMOTION }]
      }
    ]
  }
}

export const DEPOSIT_LIST = [
  {
    key: 'nicepayBanks',
    title: 'CodePay',
    image: `${PATH_IMAGE}nicepay.svg`,
    link: ACCOUNT_URLS.DEPOSIT_DA,
    isMaintain: false

  },
  {
    key: 'bankings',
    title: 'CodePay2',
    image: `${PATH_IMAGE}codepay2.svg`,
    link: ACCOUNT_URLS.DEPOSIT_CODEPAY2,
    isMaintain: false

  },
  {
    key: 'p2p',
    title: 'P2P',
    image: `${PATH_IMAGE}p2p.svg`,
    link: ACCOUNT_URLS.P2P,
    isMaintain: false

  },
  {
    key: 'crypto_v2',
    title: 'Tiền ảo',
    image: `${PATH_IMAGE}crypto.svg`,
    link: ACCOUNT_URLS.DEPOSIT_CRYPTO,
    isMaintain: false

  },
  {
    key: 'momos',
    title: 'Ví điện tử',
    image: `${PATH_IMAGE}digital-wallet.svg`,
    link: ACCOUNT_URLS.DEPOSIT_MOMO,
    isMaintain: false

  },
  {
    key: 'ViettelPays',
    title: 'Viettel Money',
    image: `${PATH_IMAGE}viettelpay.svg`,
    link: ACCOUNT_URLS.DEPOSIT_VIETTEL_PAY,
    isMaintain: false

  },
  {
    key: 'cardList',
    title: 'Thẻ cào',
    image: `${PATH_IMAGE}card.svg`,
    link: ACCOUNT_URLS.DEPOSIT_CARD,
    isMaintain: false
  }
]

export const footerContentData = () => {
  const { $config } = useNuxtApp()
  return {
    SLOGAN: `${$config.BRANCH_NAME} - Nhà cái thể thao trực tuyến, Siêu chợ games đổi thưởng đỉnh cao`,
    DESCRIPTION: `Nhà cái ${$config.BRANCH_NAME} là trang cược bóng đá nhanh nhất trên thị trường hiện nay, hoàn trả mỗi ngày cao nhất 1.8%. ${$config.BRANCH_NAME} cung cấp hai loại bảng kèo K-Sports; C-Sports và tỷ lệ cược liên tục cập nhật từng giây. Lịch thi đấu mỗi ngày, phân tích trận đấu, soi kèo banh Ngoại Hạnh Anh, Cúp C1, Serie A, Laliga, Bundesliga, Ligue 1,... và tất cả các giải lớn nhỏ khác. Trải nghiệm mượt mà, mọi lúc mọi nơi.`,
    SLOGAN_DESCRIPTION: `${$config.BRANCH_NAME} là trang cá cược thể thao hấp dẫn, cung cấp đa dạng về sản phẩm trò chơi như Thể Thao, Trò Chơi, Casino Trực Tuyến và thưởng hoàn trả cao nhất trên thị trường.`,
    VIEW_MORE: 'Xem thêm',
    COPY_RIGHT: `Copyright © 2024 Powered By ${$config.BRANCH_NAME} All Rights Reserved.`
  }
}
