<template>
  <form class="login-form">
    <BaseInput
      ref="username"
      v-model="formData.username"
      :label="FORM_AUTH.LABEL_USERNAME"
      name="username"
      placeholder=" "
      :min-value="0"
      :min-length="+$config.MIN_LENGTH_USER_NAME"
      :max-length="+$config.MAX_LENGTH_USER_NAME"
      :limit-input="+$config.MAX_LENGTH_USER_NAME"
      required
      :error-special-character="FORM_AUTH.VALIDATE_USERNAME"
      @keyup.enter="password?.focus()"
    />
    <BaseInput
      ref="password"
      v-model.trFormLoginim="formData.password"
      :label="FORM_AUTH.LABEL_PASSWORD"
      name="password"
      type="password"
      placeholder=" "
      required
      :min-length="+$config.MIN_LENGTH_PASSWORD"
      :max-length="+$config.MAX_LENGTH_PASSWORD"
      :limit-input="+$config.MAX_LENGTH_PASSWORD"
      :allow-validate-password="false"
      @keyup.enter="submit"
    />
    <div class="d-flex justify-content-end">
      <div class="forgot-password" @click="onForgotPassword()">
        {{ FORM_AUTH.FORGOT_PASSWORD }}
      </div>
    </div>
    <BaseButton
      class="base-button--btn-primary"
      :is-loading="isLoading"
      type="submit"
      @click="submit"
    >
      {{ FORM_AUTH.LOGIN }}
    </BaseButton>
    <div class="redirect">
      Bạn chưa có tài khoản?
      <span @click="openRegisterPopup()">Đăng Ký</span>
    </div>
  </form>
</template>
<script setup lang="ts">
import { FORM_AUTH } from '~/constants/form/auth'
import { useLogin } from '~/composables/user/useLogin'
import BaseInput from '~/components/common/base-input.vue'
import BaseButton from '~/components/common/base-button.vue'
import { MODAL_TYPES } from '~/config/constant'
const { $config } = useNuxtApp()
const { username, password, submit, formData, isLoading } = useLogin()
const route = useRoute()
const { openModalWithNavigate, closeModal } = useModal()

const openRegisterPopup = () => {
  navigateTo({
    path: location.pathname,
    query: { ...route.query, popup: 'register' }
  })
}

const props = defineProps({
  isShowModal: {
    type: Boolean,
    default: false
  },
  tabActive: {
    type: String,
    default: ''
  }
})
const disabledBtn = computed(() => {
  return !formData.username || !formData.password
})

watch(route, () => {
  username?.value?.resetValidate()
  password?.value?.resetValidate()
})

const onForgotPassword = () => {
  navigateTo({
    path: location.pathname,
    query: { ...route.query, popup: 'forgot-password' }
  })
}
const handleKeyPress = async (event: KeyboardEvent) => {
  if (event.key === 'Enter') {
    await submit()
  }
}

onMounted(() => {
  window.addEventListener('keyup', handleKeyPress)
})

onUnmounted(() => {
  window.removeEventListener('keyup', handleKeyPress)
})
</script>
<style lang="scss" scoped src="public/assets/scss/components/common/form/login.scss" />
