import validate from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/node_modules/nuxt/dist/pages/runtime/validate.js";
import init_45global from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/middleware/init.global.ts";
import redirect_45global from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/middleware/redirect.global.ts";
import set_45layout_45global from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/middleware/setLayout.global.ts";
export const globalMiddleware = [
  validate,
  init_45global,
  redirect_45global,
  set_45layout_45global
]
export const namedMiddleware = {
  auth: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/middleware/auth.ts"),
  "deposit-maintain": () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/middleware/depositMaintain.ts"),
  "page-desktop": () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/middleware/pageDesktop.ts"),
  "page-mobile": () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/middleware/pageMobile.ts"),
  "un-auth": () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/middleware/unAuth.ts")
}