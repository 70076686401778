export const NOTE_LABEL = 'Lưu ý: '
export const TYPE_METHOD = {
  ALL: 'all',
  PAYWIN: 'paywin',
  BANK: 'bank',
  MOMO: 'momo',
  CARD: 'card',
  CODEPAY: 'codepay',
  CRYPTO: 'crypto',
  BET: 'bet',
  TRANSACTION: 'transaction',
  IN_GAME: 'in_game',
  FISHING: 'fishing',
  SLOTS: 'slots',
  GAME_BAI: 'game_bai',
  LODE: 'lo_de',
  TABLES: 'tables',
  LOTTERY: 'lottery',
  INSTANCE_GAME: 'instance_game',
  JACKPOT: 'jackpot',
  EVO: 'evo',
  VIVO: 'vivo',
  EBET: 'ebet',
  EZUGI: 'ezugi',
  PRAGMATIC: 'pragmatic',
  N2_LIVE: 'n2',
  MICROGAMING: 'mg',
  HOGAMING: 'hogaming'
}
export const LOGIN = 'Đăng nhập'

export const PAGE_PARAMS = {
  ABOUT_US: 'introduce',
  WITHDRAW: 'withdraw',
  POLICY: 'policy',
  SECURITY: 'security',
  RESPONSIBILITY: 'responsibility',
  RULE: 'rule',
  DEPOSIT: 'deposit',
  REGISTER: 'register'
}

export const TYPE_GUIDE = {
  BANK: 'BANK',
  CARD: 'CARD',
  CODEPAY: 'CODE_PAY',
  CRYPTO: 'CRYPTO',
  MOMO: 'MOMO',
  PAYWIN: 'PAY_WIN'
}

export const PAGE_HIDE_HEADER_FOOTER_MB = [
  'error',
  'account-profile',
  'account-bank',
  '/account/deposit?type=codepay',
  '/account/deposit?type=momo',
  '/account/deposit/codepay',
  '/account/deposit/momo',
  '/account/deposit/bank?type=codepay',
  '/account/deposit/bank?type=codepay2',
  '/account/history/bet',
  '/account/history/transaction',
  'about-us',
  'support',
  'question',
  '/account/withdraw?type=bank',
  '/account/withdraw?type=card',
  '/account/deposit?type=card',
  '/account/deposit?type=paywin',
  '/account/deposit?type=crypto',
  '/account/deposit?type=bank',
  '/account/withdraw/bank',
  '/account/deposit/senpay',
  '/account/withdraw/card',
  '/account/deposit/card',
  '/account/deposit/paywin',
  '/account/deposit/crypto',
  '/account/deposit/bank',
  '/account/deposit/dapay',
  '/account/deposit/codepay',
  '/account/deposit/codepay2',
  'account-deposit-ewallet',
  '/account/withdraw/crypto',
  '/account/trading-p2p',
  '/account/trading-p2p?type=buy',
  '/account/trading-p2p?type=sell',
  'verify-email',
  'sports-schedule',
  'sports-schedule-match',
  'id',
  'slug',
  'user-notification',
  'the-thao-lich-thi-dau',
  'gioi-thieu',
  'gioi-thieu-alias',
  'huong-dan',
  'huong-dan-alias',
  'cau-hoi-thuong-gap',
  'cau-hoi-thuong-gap-alias',
  'account-history-transaction',
  'the-thao-id',
  'account-bonus',
  'the-thao-lich-thi-dau-chi-tiet',
  'the-thao-lich-thi-dau-chi-tiet-tran-dau'
]

export const PAGE_HIDE_HEADER_MB = [
  'account-deposit',
  'account-deposit-bank',
  'account-withdraw',
  'sports-schedule',
  'account-history-transaction',
  'account-history-bet',
  'news-detail-id',
  'khuyen-mai-vip',
  'khuyen-mai-id',
  'tin-tuc-alias',
  'tin-tuc-the-id',
  'account-bonus',
  'user-notification',
  'the-thao-lich-thi-dau',
  'gioi-thieu',
  'gioi-thieu-alias',
  'huong-dan',
  'huong-dan-alias',
  'cau-hoi-thuong-gap',
  'cau-hoi-thuong-gap-alias'
]

export const ERROR = 'Đã có lỗi xảy ra, vui lòng thử lại'
export const UNIT_VND = 1000
export const CHANGE_CARD = 'Đổi thẻ cào'

export const MAX_AMOUNT_DEPOSIT = Number(process.env.MAX_AMOUNT_DEPOSIT)
export const MIN_AMOUNT_DEPOSIT = Number(process.env.MIN_AMOUNT_DEPOSIT)

export const SUGGEST_AMOUNTS_CODEPAY = ['100', '300', '1,000', '5,000', '20,000', '100,000']
export const SUGGEST_AMOUNTS = ['100', '500', '1,000', '2,000', '5,000', '10,000', '20,000', '300,000']

export const DELETE = 'Xóa'

export const NCC_TYPES = {
  KSPORTS: 'ksports',
  CSPORTS: 'csports',
  SSPORTS: 'ssports',
  MSPORTS: 'msports',
  TSPORTS: 'tsports',
  VIRTUAL_SPORTS: 'the-thao-ao',
  ESPORTS: 'the-thao-dien-tu',
  PSPORTS: 'psports',
  ASPORTS: 'asports',
  ISPORTS: 'isports'
}

export const ALT_IMAGE = {
  HERO_BANNER: 'Khuyến mãi nhà cái',
  SPORT: 'Cược bóng đá',
  VIEW_ALL: 'Xem tất cả games',
  KSPORT: 'k-sports',
  CSPORT: 'c-sports',
  SSPORT: 's-sports',
  MSPORT: 'm-sports',
  TSPORT: 't-sports',
  VIRTUAL_SPORTS: 'Virtual sports',
  ESPORT: 'e-sports',
  SPORTS_SCHEDULE: 'Sports schedule'
}
