<template>
  <footer id="footer" class="section-footer">
    <div class="footer">
      <div class="footer-top">
        <div class="container-custom">
          <div class="footer-top__content">
            <nuxt-link to="/" class="logo">
              <BaseImg relative-src="/assets/brand/logo.webp" alt="logo" @click="goHome" />
            </nuxt-link>
            <div class="footer-top__content--slogan">
              <div class="seo-content__title" v-html="dataSeo?.content_title" />
              <div class="seo-content__description" v-html="dataSeo?.content_description" />
            </div>
          </div>
        </div>
      </div>
      <div class="footer-navigation">
        <div class="container-custom">
          <div class="footer-navigation__content">
            <div v-for="item in contactNavigation" :key="item.title" class="footer-section">
              <p class="section-title">
                {{ item.title }}
              </p>
              <div v-if="item.subCategories.length <= 5">
                <nuxt-link
                  v-for="category in item.subCategories"
                  :id="category.title"
                  :key="category.title"
                  class="category-name"
                  :to="category.link"
                  :target="category?.type === 'BLANK' ? '_blank' : '_self'"
                >
                  {{ category.title }}
                </nuxt-link>
                <nuxt-link
                  v-if="item.subCategories.length < 2 && $config.SHOW_NEWS !== '0'"
                  class="category-name"
                  :to="PAGE_URLS.NEWS"
                >
                  Tin tức
                </nuxt-link>
              </div>
              <div v-else>
                <div class="row-footer">
                  <div class="column">
                    <nuxt-link
                      v-for="category in item.subCategories.slice(0, 5)"
                      :id="category.title"
                      :key="category.title"
                      class="category-name"
                      :to="category.link"
                    >
                      {{ category.title }}
                    </nuxt-link>
                  </div>
                  <div class="column-right">
                    <nuxt-link
                      v-for="category in item.subCategories.slice(5)"
                      :id="category.title"
                      :key="category.title"
                      class="category-name"
                      :to="category.link"
                    >
                      {{ category.title }}
                    </nuxt-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-deposit">
        <div class="container-custom">
          <div class="footer-deposit__content" :class="{ disableClick: !isJsEnabled}">
            <a
              v-for="item in (isJsEnabled ? footerPaymentMethods : DEPOSIT_LIST)"
              :id="item.title"
              :key="item.title"
              :href="item?.link || ''"
              @click.prevent="handleClickMethod(item, $event)"
            >
              <div v-if="!isLogged" class="deposit-item">
                <BaseImg class="deposit-image" :relative-src="item.image" :alt="item?.title" />
                <p class="deposit-title">{{ item.title }}</p>
              </div>
              <div v-else-if="isLogged && !item.isMaintain" class="deposit-item">
                <BaseImg class="deposit-image" :relative-src="item.image" :alt="item.title" />
                <p class="deposit-title">{{ item.title }}</p>
              </div>
              <div v-else class="deposit-item" :class="{ maintain: item.isMaintain }">
                <BaseImg class="deposit-image" :relative-src="item.image" :alt="item.title" />
                <p class="deposit-title">{{ item.title }}</p>
                <div v-if="item.isShowTooltip" class="tooltip">
                  <div class="tooltip-content">
                    <span class="description">Đang bảo trì</span>
                  </div>
                  <div class="arrow-down" />
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="footer-reserve">
        <div class="container-custom">
          <div class="footer-reserve__content">
            <div class="footer-reserve__left">
              <nuxt-link
                title="DMCA.com Protection Status"
                target="_blank"
                to="//www.dmca.com/Protection/Status.aspx?ID=f362511c-0d21-4b76-af37-f633d9a377ba"
              >
                <BaseImg relative-src="assets/images/components/desktop/footer/dmca.png" class="dmca" alt="copy right" />
              </nuxt-link>
              <p class="reserve-text">{{ FOOTER_CONTENT.COPY_RIGHT }}</p>
            </div>

            <BaseImg
              relative-src="assets/images/components/desktop/footer/copyright-logo.png"
              class="reserve-image"
              alt="copy right"
            />
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import isEmpty from 'lodash/isEmpty'
import { storeToRefs } from 'pinia'
import useModal from '~/composables/useModal'
import { useAppStore } from '~~/store/app'
import { PAGE_URLS } from '~/config/page-url'
import { DEPOSIT_LIST, footerContentData } from '@/constants/footer'
import { MODAL_TYPES } from '~/config/constant'
import { useContactStore } from '~/store/contact'
import useBase from '~/composables/useBase'
import { useAccountStore } from '~/store/account'

const { openModalWithNavigate } = useModal()
const { contactNavigation } = useFooterNavigation()
const router = useRouter()
const { $config, $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { currentUser, seoData, isLogged } = storeToRefs(store)
const accountStore: any = useAccountStore()
const route = useRoute()
const isJsEnabled = ref(false)

useBase()
const dataSeo = computed(() => {
  if (route.path === '/') {
    return seoData.value.find((item) => item.alias === '/')
  }
  if (route.name === 'khuyen-mai-id') {
    return seoData.value.find((item) => item.alias === 'khuyen-mai')
  }
  return seoData.value.find((item) => item.alias === route.path.slice(1)) ?? seoData.value.find((item) => item.alias === '/')
})

const { fetchBranchContact } = useContact()
const { data: contacts } = await useAsyncData('contacts', async () => {
  return await fetchBranchContact()
})

interface FooterPaymentMethod {
  key: string;
  title: string;
  image: string;
  link: string;
  isMaintain: boolean;
  isShowTooltip: boolean;
}

const FOOTER_CONTENT = footerContentData()

const footerPaymentMethods = ref<FooterPaymentMethod[]>([])
const updateFooterPaymentMethods = () => {
  footerPaymentMethods.value = DEPOSIT_LIST.map((item) => {
    let isMaintain = false
    if (!accountStore.loading) {
      switch (item.key) {
        case 'cardList':
          isMaintain = !(
            accountStore.cardList?.VIETTEL?.status === 1 ||
            accountStore.cardList?.MOBIFONE?.status === 1 ||
            accountStore.cardList?.VINAPHONE?.status === 1 ||
            accountStore.cardList?.ZING?.status === 1
          )
          break
        case 'ViettelPays':
          isMaintain = accountStore.viettelPays?.length === 0
          break
        case 'nicepayBanks':
          isMaintain = accountStore.nicepayBanks?.length === 0
          break
        case 'bankings':
          isMaintain =
            isEmpty(accountStore.providerGoPay) || accountStore.providerGoPay?.GOPAY?.bankings?.length === 0
          break
        case 'crypto_v2':
          isMaintain = accountStore.crypto_v2?.[item.key]?.length === 0
          break
        case 'momos':
          isMaintain = !accountStore.momos || accountStore.momos.length === 0
          break
        default:
          break
      }
    }
    return {
      ...item,
      isMaintain
    }
  })
}
watch(
  () => [
    accountStore.cardList,
    accountStore.viettelPays,
    accountStore.providerGoPay,
    accountStore.crypto_v2,
    accountStore.momos,
    accountStore.loading
  ],
  () => {
    updateFooterPaymentMethods()
  },
  { deep: true }
)
onMounted(() => {
  isJsEnabled.value = true
  updateFooterPaymentMethods()
})

const handleClickMethod = (item: any, event: any) => {
  if (!isJsEnabled.value) {
    return
  }
  if (currentUser.value === null) {
    openModalWithNavigate(MODAL_TYPES.LOGIN, 'login', item?.link)
  } else if (!item.isMaintain) {
    navigateTo(item?.link)
  } else {
    item.isShowTooltip = true
    setTimeout(() => {
      item.isShowTooltip = false
    }, 2000)
    event.preventDefault()
  }
}
const goHome = () => {
  router.push({ path: PAGE_URLS.HOME })
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}
</script>

<style lang="scss" scoped src="public/assets/scss/components/desktop/footer/index.scss" />
