import { default as bankfYpHAVtkG4Meta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/account/bank.vue?macro=true";
import { default as bonusamfJl6pFtzMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/account/bonus.vue?macro=true";
import { default as bankKEbUSXxVnVMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/account/deposit/bank.vue?macro=true";
import { default as cardvRmgE8YDseMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/account/deposit/card.vue?macro=true";
import { default as codepayCXkl62XqXRMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/account/deposit/codepay.vue?macro=true";
import { default as codepay2u5MpAbvNEOMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/account/deposit/codepay2.vue?macro=true";
import { default as cryptosWUjkYL3kqMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/account/deposit/crypto.vue?macro=true";
import { default as ewalletRkE7GHlEvhMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/account/deposit/ewallet.vue?macro=true";
import { default as depositrvlPbeSHWiMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/account/deposit.vue?macro=true";
import { default as betOxqHUO7rfrMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/account/history/bet.vue?macro=true";
import { default as transactionOFEltPr1WZMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/account/history/transaction.vue?macro=true";
import { default as historyh5ghBhvd4oMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/account/history.vue?macro=true";
import { default as profileZ39a3ZPrHKMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/account/profile.vue?macro=true";
import { default as trading_45p2pb94dLAnQ2oMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/account/trading-p2p.vue?macro=true";
import { default as bank45GgsW6ef4Meta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/account/withdraw/bank.vue?macro=true";
import { default as cardyNJVGevBKfMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/account/withdraw/card.vue?macro=true";
import { default as cryptoQnQUVZxUP8Meta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/account/withdraw/crypto.vue?macro=true";
import { default as accountsgktl05L07Meta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/account.vue?macro=true";
import { default as base_45buttonpb17q9PyxdMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/dev/base-button.vue?macro=true";
import { default as base_45count_45downxr1khH5OXsMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/dev/base-count-down.vue?macro=true";
import { default as base_45dropdown_45radioBTqEO5Fo2ZMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/dev/base-dropdown-radio.vue?macro=true";
import { default as base_45imgNb7yRzLiOtMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/dev/base-img.vue?macro=true";
import { default as base_45inputlKeUqq6k7hMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/dev/base-input.vue?macro=true";
import { default as base_45loading7CixpkZUQRMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/dev/base-loading.vue?macro=true";
import { default as base_45modalGT1mIkMws5Meta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/dev/base-modal.vue?macro=true";
import { default as base_45money_45inputDyULmD7WniMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/dev/base-money-input.vue?macro=true";
import { default as base_45paginationcenXLltuZvMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/dev/base-pagination.vue?macro=true";
import { default as base_45select_45bankwtDhv4WscyMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/dev/base-select-bank.vue?macro=true";
import { default as game_45itemhmFQIlSmR4Meta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/dev/game-item.vue?macro=true";
import { default as index7vFviEcPcmMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/dev/index.vue?macro=true";
import { default as _91type_93VLARVm8XfRMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/game/[type].vue?macro=true";
import { default as gameHaos5ZtEJKMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/game.vue?macro=true";
import { default as _91alias_93CLYcZ8OT0OMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/gioi-thieu/[alias].vue?macro=true";
import { default as gioi_45thieuCf8aXWlkxYMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/gioi-thieu.vue?macro=true";
import { default as _91alias_93V3aSDWImF8Meta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/huong-dan/[alias].vue?macro=true";
import { default as huong_45dantn4hFU3IuZMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/huong-dan.vue?macro=true";
import { default as indexk3T1YOs6yBMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/index.vue?macro=true";
import { default as _91id_93kIEvGXOB0vMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/khuyen-mai/[id].vue?macro=true";
import { default as index90GmzRHVRbMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/khuyen-mai/index.vue?macro=true";
import { default as _91id_93c4MTjcYR3wMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/khuyen-mai/the-loai/[id].vue?macro=true";
import { default as vipZpCnz2jeDmMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/khuyen-mai/vip.vue?macro=true";
import { default as _91type_93yHxVpeAUZ4Meta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/livecasino/[type].vue?macro=true";
import { default as index4ANkr2hvK2Meta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/livecasino/index.vue?macro=true";
import { default as livecasinopd0XoTovXqMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/livecasino.vue?macro=true";
import { default as reset_45password5Qsg5BD3S9Meta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/reset-password.vue?macro=true";
import { default as searchvqgCJ2CHhRMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/search.vue?macro=true";
import { default as _91id_932v53wiHvoJMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/the-thao/[id].vue?macro=true";
import { default as indexxsTl0aKuHNMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/the-thao/index.vue?macro=true";
import { default as index2B911tpWWeMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/the-thao/lich-thi-dau/chi-tiet-tran-dau/index.vue?macro=true";
import { default as indexzc7kWwEauCMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/the-thao/lich-thi-dau/index.vue?macro=true";
import { default as _91alias_93Hv5VsAUMOCMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/tin-tuc/[alias].vue?macro=true";
import { default as index8MiZSWLAgMMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/tin-tuc/index.vue?macro=true";
import { default as _91Slug_93Bt0VWPvLD0Meta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/tin-tuc/the-loai/[Slug].vue?macro=true";
import { default as verify_45mailWgBHWGALvcMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/verify-mail.vue?macro=true";
export default [
  {
    name: accountsgktl05L07Meta?.name ?? "account",
    path: accountsgktl05L07Meta?.path ?? "/account",
    children: [
  {
    name: bankfYpHAVtkG4Meta?.name ?? "account-bank",
    path: bankfYpHAVtkG4Meta?.path ?? "bank",
    meta: bankfYpHAVtkG4Meta || {},
    alias: bankfYpHAVtkG4Meta?.alias || [],
    redirect: bankfYpHAVtkG4Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/account/bank.vue").then(m => m.default || m)
  },
  {
    name: bonusamfJl6pFtzMeta?.name ?? "account-bonus",
    path: bonusamfJl6pFtzMeta?.path ?? "bonus",
    meta: bonusamfJl6pFtzMeta || {},
    alias: bonusamfJl6pFtzMeta?.alias || [],
    redirect: bonusamfJl6pFtzMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/account/bonus.vue").then(m => m.default || m)
  },
  {
    name: depositrvlPbeSHWiMeta?.name ?? "account-deposit",
    path: depositrvlPbeSHWiMeta?.path ?? "deposit",
    children: [
  {
    name: bankKEbUSXxVnVMeta?.name ?? "account-deposit-bank",
    path: bankKEbUSXxVnVMeta?.path ?? "bank",
    meta: bankKEbUSXxVnVMeta || {},
    alias: bankKEbUSXxVnVMeta?.alias || [],
    redirect: bankKEbUSXxVnVMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/account/deposit/bank.vue").then(m => m.default || m)
  },
  {
    name: cardvRmgE8YDseMeta?.name ?? "account-deposit-card",
    path: cardvRmgE8YDseMeta?.path ?? "card",
    meta: cardvRmgE8YDseMeta || {},
    alias: cardvRmgE8YDseMeta?.alias || [],
    redirect: cardvRmgE8YDseMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/account/deposit/card.vue").then(m => m.default || m)
  },
  {
    name: codepayCXkl62XqXRMeta?.name ?? "account-deposit-codepay",
    path: codepayCXkl62XqXRMeta?.path ?? "codepay",
    meta: codepayCXkl62XqXRMeta || {},
    alias: codepayCXkl62XqXRMeta?.alias || [],
    redirect: codepayCXkl62XqXRMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/account/deposit/codepay.vue").then(m => m.default || m)
  },
  {
    name: codepay2u5MpAbvNEOMeta?.name ?? "account-deposit-codepay2",
    path: codepay2u5MpAbvNEOMeta?.path ?? "codepay2",
    meta: codepay2u5MpAbvNEOMeta || {},
    alias: codepay2u5MpAbvNEOMeta?.alias || [],
    redirect: codepay2u5MpAbvNEOMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/account/deposit/codepay2.vue").then(m => m.default || m)
  },
  {
    name: cryptosWUjkYL3kqMeta?.name ?? "account-deposit-crypto",
    path: cryptosWUjkYL3kqMeta?.path ?? "crypto",
    meta: cryptosWUjkYL3kqMeta || {},
    alias: cryptosWUjkYL3kqMeta?.alias || [],
    redirect: cryptosWUjkYL3kqMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/account/deposit/crypto.vue").then(m => m.default || m)
  },
  {
    name: ewalletRkE7GHlEvhMeta?.name ?? "account-deposit-ewallet",
    path: ewalletRkE7GHlEvhMeta?.path ?? "ewallet",
    meta: ewalletRkE7GHlEvhMeta || {},
    alias: ewalletRkE7GHlEvhMeta?.alias || [],
    redirect: ewalletRkE7GHlEvhMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/account/deposit/ewallet.vue").then(m => m.default || m)
  }
],
    meta: depositrvlPbeSHWiMeta || {},
    alias: depositrvlPbeSHWiMeta?.alias || [],
    redirect: depositrvlPbeSHWiMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/account/deposit.vue").then(m => m.default || m)
  },
  {
    name: historyh5ghBhvd4oMeta?.name ?? "account-history",
    path: historyh5ghBhvd4oMeta?.path ?? "history",
    children: [
  {
    name: betOxqHUO7rfrMeta?.name ?? "account-history-bet",
    path: betOxqHUO7rfrMeta?.path ?? "bet",
    meta: betOxqHUO7rfrMeta || {},
    alias: betOxqHUO7rfrMeta?.alias || [],
    redirect: betOxqHUO7rfrMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/account/history/bet.vue").then(m => m.default || m)
  },
  {
    name: transactionOFEltPr1WZMeta?.name ?? "account-history-transaction",
    path: transactionOFEltPr1WZMeta?.path ?? "transaction",
    meta: transactionOFEltPr1WZMeta || {},
    alias: transactionOFEltPr1WZMeta?.alias || [],
    redirect: transactionOFEltPr1WZMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/account/history/transaction.vue").then(m => m.default || m)
  }
],
    meta: historyh5ghBhvd4oMeta || {},
    alias: historyh5ghBhvd4oMeta?.alias || [],
    redirect: historyh5ghBhvd4oMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/account/history.vue").then(m => m.default || m)
  },
  {
    name: profileZ39a3ZPrHKMeta?.name ?? "account-profile",
    path: profileZ39a3ZPrHKMeta?.path ?? "profile",
    meta: profileZ39a3ZPrHKMeta || {},
    alias: profileZ39a3ZPrHKMeta?.alias || [],
    redirect: profileZ39a3ZPrHKMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: trading_45p2pb94dLAnQ2oMeta?.name ?? "account-trading-p2p",
    path: trading_45p2pb94dLAnQ2oMeta?.path ?? "trading-p2p",
    meta: trading_45p2pb94dLAnQ2oMeta || {},
    alias: trading_45p2pb94dLAnQ2oMeta?.alias || [],
    redirect: trading_45p2pb94dLAnQ2oMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/account/trading-p2p.vue").then(m => m.default || m)
  },
  {
    name: bank45GgsW6ef4Meta?.name ?? "account-withdraw-bank",
    path: bank45GgsW6ef4Meta?.path ?? "withdraw/bank",
    meta: bank45GgsW6ef4Meta || {},
    alias: bank45GgsW6ef4Meta?.alias || [],
    redirect: bank45GgsW6ef4Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/account/withdraw/bank.vue").then(m => m.default || m)
  },
  {
    name: cardyNJVGevBKfMeta?.name ?? "account-withdraw-card",
    path: cardyNJVGevBKfMeta?.path ?? "withdraw/card",
    meta: cardyNJVGevBKfMeta || {},
    alias: cardyNJVGevBKfMeta?.alias || [],
    redirect: cardyNJVGevBKfMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/account/withdraw/card.vue").then(m => m.default || m)
  },
  {
    name: cryptoQnQUVZxUP8Meta?.name ?? "account-withdraw-crypto",
    path: cryptoQnQUVZxUP8Meta?.path ?? "withdraw/crypto",
    meta: cryptoQnQUVZxUP8Meta || {},
    alias: cryptoQnQUVZxUP8Meta?.alias || [],
    redirect: cryptoQnQUVZxUP8Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/account/withdraw/crypto.vue").then(m => m.default || m)
  }
],
    meta: accountsgktl05L07Meta || {},
    alias: accountsgktl05L07Meta?.alias || [],
    redirect: accountsgktl05L07Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/account.vue").then(m => m.default || m)
  },
  {
    name: base_45buttonpb17q9PyxdMeta?.name ?? "dev-base-button",
    path: base_45buttonpb17q9PyxdMeta?.path ?? "/dev/base-button",
    meta: base_45buttonpb17q9PyxdMeta || {},
    alias: base_45buttonpb17q9PyxdMeta?.alias || [],
    redirect: base_45buttonpb17q9PyxdMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/dev/base-button.vue").then(m => m.default || m)
  },
  {
    name: base_45count_45downxr1khH5OXsMeta?.name ?? "dev-base-count-down",
    path: base_45count_45downxr1khH5OXsMeta?.path ?? "/dev/base-count-down",
    meta: base_45count_45downxr1khH5OXsMeta || {},
    alias: base_45count_45downxr1khH5OXsMeta?.alias || [],
    redirect: base_45count_45downxr1khH5OXsMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/dev/base-count-down.vue").then(m => m.default || m)
  },
  {
    name: base_45dropdown_45radioBTqEO5Fo2ZMeta?.name ?? "dev-base-dropdown-radio",
    path: base_45dropdown_45radioBTqEO5Fo2ZMeta?.path ?? "/dev/base-dropdown-radio",
    meta: base_45dropdown_45radioBTqEO5Fo2ZMeta || {},
    alias: base_45dropdown_45radioBTqEO5Fo2ZMeta?.alias || [],
    redirect: base_45dropdown_45radioBTqEO5Fo2ZMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/dev/base-dropdown-radio.vue").then(m => m.default || m)
  },
  {
    name: base_45imgNb7yRzLiOtMeta?.name ?? "dev-base-img",
    path: base_45imgNb7yRzLiOtMeta?.path ?? "/dev/base-img",
    meta: base_45imgNb7yRzLiOtMeta || {},
    alias: base_45imgNb7yRzLiOtMeta?.alias || [],
    redirect: base_45imgNb7yRzLiOtMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/dev/base-img.vue").then(m => m.default || m)
  },
  {
    name: base_45inputlKeUqq6k7hMeta?.name ?? "dev-base-input",
    path: base_45inputlKeUqq6k7hMeta?.path ?? "/dev/base-input",
    meta: base_45inputlKeUqq6k7hMeta || {},
    alias: base_45inputlKeUqq6k7hMeta?.alias || [],
    redirect: base_45inputlKeUqq6k7hMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/dev/base-input.vue").then(m => m.default || m)
  },
  {
    name: base_45loading7CixpkZUQRMeta?.name ?? "dev-base-loading",
    path: base_45loading7CixpkZUQRMeta?.path ?? "/dev/base-loading",
    meta: base_45loading7CixpkZUQRMeta || {},
    alias: base_45loading7CixpkZUQRMeta?.alias || [],
    redirect: base_45loading7CixpkZUQRMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/dev/base-loading.vue").then(m => m.default || m)
  },
  {
    name: base_45modalGT1mIkMws5Meta?.name ?? "dev-base-modal",
    path: base_45modalGT1mIkMws5Meta?.path ?? "/dev/base-modal",
    meta: base_45modalGT1mIkMws5Meta || {},
    alias: base_45modalGT1mIkMws5Meta?.alias || [],
    redirect: base_45modalGT1mIkMws5Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/dev/base-modal.vue").then(m => m.default || m)
  },
  {
    name: base_45money_45inputDyULmD7WniMeta?.name ?? "dev-base-money-input",
    path: base_45money_45inputDyULmD7WniMeta?.path ?? "/dev/base-money-input",
    meta: base_45money_45inputDyULmD7WniMeta || {},
    alias: base_45money_45inputDyULmD7WniMeta?.alias || [],
    redirect: base_45money_45inputDyULmD7WniMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/dev/base-money-input.vue").then(m => m.default || m)
  },
  {
    name: base_45paginationcenXLltuZvMeta?.name ?? "dev-base-pagination",
    path: base_45paginationcenXLltuZvMeta?.path ?? "/dev/base-pagination",
    meta: base_45paginationcenXLltuZvMeta || {},
    alias: base_45paginationcenXLltuZvMeta?.alias || [],
    redirect: base_45paginationcenXLltuZvMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/dev/base-pagination.vue").then(m => m.default || m)
  },
  {
    name: base_45select_45bankwtDhv4WscyMeta?.name ?? "dev-base-select-bank",
    path: base_45select_45bankwtDhv4WscyMeta?.path ?? "/dev/base-select-bank",
    meta: base_45select_45bankwtDhv4WscyMeta || {},
    alias: base_45select_45bankwtDhv4WscyMeta?.alias || [],
    redirect: base_45select_45bankwtDhv4WscyMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/dev/base-select-bank.vue").then(m => m.default || m)
  },
  {
    name: game_45itemhmFQIlSmR4Meta?.name ?? "dev-game-item",
    path: game_45itemhmFQIlSmR4Meta?.path ?? "/dev/game-item",
    meta: game_45itemhmFQIlSmR4Meta || {},
    alias: game_45itemhmFQIlSmR4Meta?.alias || [],
    redirect: game_45itemhmFQIlSmR4Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/dev/game-item.vue").then(m => m.default || m)
  },
  {
    name: index7vFviEcPcmMeta?.name ?? "dev",
    path: index7vFviEcPcmMeta?.path ?? "/dev",
    meta: index7vFviEcPcmMeta || {},
    alias: index7vFviEcPcmMeta?.alias || [],
    redirect: index7vFviEcPcmMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/dev/index.vue").then(m => m.default || m)
  },
  {
    name: gameHaos5ZtEJKMeta?.name ?? "game",
    path: gameHaos5ZtEJKMeta?.path ?? "/game",
    children: [
  {
    name: _91type_93VLARVm8XfRMeta?.name ?? "game-type",
    path: _91type_93VLARVm8XfRMeta?.path ?? ":type()",
    meta: _91type_93VLARVm8XfRMeta || {},
    alias: _91type_93VLARVm8XfRMeta?.alias || [],
    redirect: _91type_93VLARVm8XfRMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/game/[type].vue").then(m => m.default || m)
  }
],
    meta: gameHaos5ZtEJKMeta || {},
    alias: gameHaos5ZtEJKMeta?.alias || [],
    redirect: gameHaos5ZtEJKMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/game.vue").then(m => m.default || m)
  },
  {
    name: gioi_45thieuCf8aXWlkxYMeta?.name ?? "gioi-thieu",
    path: gioi_45thieuCf8aXWlkxYMeta?.path ?? "/gioi-thieu",
    children: [
  {
    name: _91alias_93CLYcZ8OT0OMeta?.name ?? "gioi-thieu-alias",
    path: _91alias_93CLYcZ8OT0OMeta?.path ?? ":alias()",
    meta: _91alias_93CLYcZ8OT0OMeta || {},
    alias: _91alias_93CLYcZ8OT0OMeta?.alias || [],
    redirect: _91alias_93CLYcZ8OT0OMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/gioi-thieu/[alias].vue").then(m => m.default || m)
  }
],
    meta: gioi_45thieuCf8aXWlkxYMeta || {},
    alias: gioi_45thieuCf8aXWlkxYMeta?.alias || [],
    redirect: gioi_45thieuCf8aXWlkxYMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/gioi-thieu.vue").then(m => m.default || m)
  },
  {
    name: huong_45dantn4hFU3IuZMeta?.name ?? "huong-dan",
    path: huong_45dantn4hFU3IuZMeta?.path ?? "/huong-dan",
    children: [
  {
    name: _91alias_93V3aSDWImF8Meta?.name ?? "huong-dan-alias",
    path: _91alias_93V3aSDWImF8Meta?.path ?? ":alias()",
    meta: _91alias_93V3aSDWImF8Meta || {},
    alias: _91alias_93V3aSDWImF8Meta?.alias || [],
    redirect: _91alias_93V3aSDWImF8Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/huong-dan/[alias].vue").then(m => m.default || m)
  }
],
    meta: huong_45dantn4hFU3IuZMeta || {},
    alias: huong_45dantn4hFU3IuZMeta?.alias || [],
    redirect: huong_45dantn4hFU3IuZMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/huong-dan.vue").then(m => m.default || m)
  },
  {
    name: indexk3T1YOs6yBMeta?.name ?? "index",
    path: indexk3T1YOs6yBMeta?.path ?? "/",
    meta: indexk3T1YOs6yBMeta || {},
    alias: indexk3T1YOs6yBMeta?.alias || [],
    redirect: indexk3T1YOs6yBMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93kIEvGXOB0vMeta?.name ?? "khuyen-mai-id",
    path: _91id_93kIEvGXOB0vMeta?.path ?? "/khuyen-mai/:id()",
    meta: _91id_93kIEvGXOB0vMeta || {},
    alias: _91id_93kIEvGXOB0vMeta?.alias || [],
    redirect: _91id_93kIEvGXOB0vMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/khuyen-mai/[id].vue").then(m => m.default || m)
  },
  {
    name: index90GmzRHVRbMeta?.name ?? "khuyen-mai",
    path: index90GmzRHVRbMeta?.path ?? "/khuyen-mai",
    meta: index90GmzRHVRbMeta || {},
    alias: index90GmzRHVRbMeta?.alias || [],
    redirect: index90GmzRHVRbMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/khuyen-mai/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93c4MTjcYR3wMeta?.name ?? "khuyen-mai-the-loai-id",
    path: _91id_93c4MTjcYR3wMeta?.path ?? "/khuyen-mai/the-loai/:id()",
    meta: _91id_93c4MTjcYR3wMeta || {},
    alias: _91id_93c4MTjcYR3wMeta?.alias || [],
    redirect: _91id_93c4MTjcYR3wMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/khuyen-mai/the-loai/[id].vue").then(m => m.default || m)
  },
  {
    name: vipZpCnz2jeDmMeta?.name ?? "khuyen-mai-vip",
    path: vipZpCnz2jeDmMeta?.path ?? "/khuyen-mai/vip",
    meta: vipZpCnz2jeDmMeta || {},
    alias: vipZpCnz2jeDmMeta?.alias || [],
    redirect: vipZpCnz2jeDmMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/khuyen-mai/vip.vue").then(m => m.default || m)
  },
  {
    path: livecasinopd0XoTovXqMeta?.path ?? "/livecasino",
    children: [
  {
    name: _91type_93yHxVpeAUZ4Meta?.name ?? "livecasino-type",
    path: _91type_93yHxVpeAUZ4Meta?.path ?? ":type()",
    meta: _91type_93yHxVpeAUZ4Meta || {},
    alias: _91type_93yHxVpeAUZ4Meta?.alias || [],
    redirect: _91type_93yHxVpeAUZ4Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/livecasino/[type].vue").then(m => m.default || m)
  },
  {
    name: index4ANkr2hvK2Meta?.name ?? "livecasino",
    path: index4ANkr2hvK2Meta?.path ?? "",
    meta: index4ANkr2hvK2Meta || {},
    alias: index4ANkr2hvK2Meta?.alias || [],
    redirect: index4ANkr2hvK2Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/livecasino/index.vue").then(m => m.default || m)
  }
],
    name: livecasinopd0XoTovXqMeta?.name ?? undefined,
    meta: livecasinopd0XoTovXqMeta || {},
    alias: livecasinopd0XoTovXqMeta?.alias || [],
    redirect: livecasinopd0XoTovXqMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/livecasino.vue").then(m => m.default || m)
  },
  {
    name: reset_45password5Qsg5BD3S9Meta?.name ?? "reset-password",
    path: reset_45password5Qsg5BD3S9Meta?.path ?? "/reset-password",
    meta: reset_45password5Qsg5BD3S9Meta || {},
    alias: reset_45password5Qsg5BD3S9Meta?.alias || [],
    redirect: reset_45password5Qsg5BD3S9Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: searchvqgCJ2CHhRMeta?.name ?? "search",
    path: searchvqgCJ2CHhRMeta?.path ?? "/search",
    meta: searchvqgCJ2CHhRMeta || {},
    alias: searchvqgCJ2CHhRMeta?.alias || [],
    redirect: searchvqgCJ2CHhRMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/search.vue").then(m => m.default || m)
  },
  {
    name: _91id_932v53wiHvoJMeta?.name ?? "the-thao-id",
    path: _91id_932v53wiHvoJMeta?.path ?? "/the-thao/:id()",
    meta: _91id_932v53wiHvoJMeta || {},
    alias: _91id_932v53wiHvoJMeta?.alias || [],
    redirect: _91id_932v53wiHvoJMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/the-thao/[id].vue").then(m => m.default || m)
  },
  {
    name: indexxsTl0aKuHNMeta?.name ?? "the-thao",
    path: indexxsTl0aKuHNMeta?.path ?? "/the-thao",
    meta: indexxsTl0aKuHNMeta || {},
    alias: indexxsTl0aKuHNMeta?.alias || [],
    redirect: indexxsTl0aKuHNMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/the-thao/index.vue").then(m => m.default || m)
  },
  {
    name: index2B911tpWWeMeta?.name ?? "the-thao-lich-thi-dau-chi-tiet-tran-dau",
    path: index2B911tpWWeMeta?.path ?? "/the-thao/lich-thi-dau/chi-tiet-tran-dau",
    meta: index2B911tpWWeMeta || {},
    alias: index2B911tpWWeMeta?.alias || [],
    redirect: index2B911tpWWeMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/the-thao/lich-thi-dau/chi-tiet-tran-dau/index.vue").then(m => m.default || m)
  },
  {
    name: indexzc7kWwEauCMeta?.name ?? "the-thao-lich-thi-dau",
    path: indexzc7kWwEauCMeta?.path ?? "/the-thao/lich-thi-dau",
    meta: indexzc7kWwEauCMeta || {},
    alias: indexzc7kWwEauCMeta?.alias || [],
    redirect: indexzc7kWwEauCMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/the-thao/lich-thi-dau/index.vue").then(m => m.default || m)
  },
  {
    name: _91alias_93Hv5VsAUMOCMeta?.name ?? "tin-tuc-alias",
    path: _91alias_93Hv5VsAUMOCMeta?.path ?? "/tin-tuc/:alias()",
    meta: _91alias_93Hv5VsAUMOCMeta || {},
    alias: _91alias_93Hv5VsAUMOCMeta?.alias || [],
    redirect: _91alias_93Hv5VsAUMOCMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/tin-tuc/[alias].vue").then(m => m.default || m)
  },
  {
    name: index8MiZSWLAgMMeta?.name ?? "tin-tuc",
    path: index8MiZSWLAgMMeta?.path ?? "/tin-tuc",
    meta: index8MiZSWLAgMMeta || {},
    alias: index8MiZSWLAgMMeta?.alias || [],
    redirect: index8MiZSWLAgMMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/tin-tuc/index.vue").then(m => m.default || m)
  },
  {
    name: _91Slug_93Bt0VWPvLD0Meta?.name ?? "tin-tuc-the-loai-Slug",
    path: _91Slug_93Bt0VWPvLD0Meta?.path ?? "/tin-tuc/the-loai/:Slug()",
    meta: _91Slug_93Bt0VWPvLD0Meta || {},
    alias: _91Slug_93Bt0VWPvLD0Meta?.alias || [],
    redirect: _91Slug_93Bt0VWPvLD0Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/tin-tuc/the-loai/[Slug].vue").then(m => m.default || m)
  },
  {
    name: verify_45mailWgBHWGALvcMeta?.name ?? "verify-mail",
    path: verify_45mailWgBHWGALvcMeta?.path ?? "/verify-mail",
    meta: verify_45mailWgBHWGALvcMeta || {},
    alias: verify_45mailWgBHWGALvcMeta?.alias || [],
    redirect: verify_45mailWgBHWGALvcMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps26-web/pages/verify-mail.vue").then(m => m.default || m)
  }
]