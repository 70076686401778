<template>
  <div class="home">
    <Banner />
    <GameCenter />
    <Stream />
    <div class="container-custom">
      <SectionGame />
      <SectionTopBet />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useJackpot } from '~~/composables/game/useJackpot'
const Banner = defineAsyncComponent(() => import('@/components/desktop/pages/home/banner/index.vue'))
const GameCenter = defineAsyncComponent(() => import('@/components/desktop/pages/home/game-center/index.vue'))
const SectionTopBet = defineAsyncComponent(() => import('@/components/desktop/pages/home/top-bet/index.vue'))
const SectionGame = defineAsyncComponent(() => import('@/components/desktop/pages/home/section-game/index.vue'))
const Stream = defineAsyncComponent(() => import('@/components/desktop/pages/home/stream/index.vue'))
const { fetchJackpot } = useJackpot()

onMounted(async () => {
  await fetchJackpot()
})
</script>

<style lang="scss" scoped src="public/assets/scss/components/desktop/pages/home/index.scss"></style>
