<template>
  <div id="header" class="header">
    <div class="header__container container-custom">
      <div class="header-menu">
        <nuxt-link id="logo" class="logo" :to="PAGE_URLS.HOME">
          <BaseImg relative-src="/assets/brand/logo.webp" alt="logo" />
        </nuxt-link>
        <div class="menu">
          <nuxt-link
            v-for="(item, index) in jsStatus ? menuList : menuListDefault"
            :id="`link-${item?.name}`"
            :key="index"
            class="menu__item"
            :class="[
              item?.name,
              {
                openSubMenu: openSubMenu === item?.name,
                active:
                  ((item?.name === route?.name && !(route?.name === 'game-type' && route.params.type === 'quay-so')) ||
                    route?.fullPath?.startsWith(item?.activeUrl) ||
                    route?.fullPath?.startsWith(item?.url)) &&
                  item?.name !== 'line'
              },
              {
                mwidth: (index == 1 || index == 3)
              }
            ]"
            :to="item?.url"
            @mouseover="onMouseOver(item)"
            @mouseleave="onMouseLeave()"
            @click="onHideMenu()"
          >
            <span class="menu-title">{{ item.title }}</span>
            <span v-if="item.subMenu && item.subMenu.length" class="icon-menu-down" />
            <div v-if="item.subMenu && item.subMenu.length" class="sub-menu" :class="item?.name">
              <div class="sub-menu__container" :class="{ gridmenu: item.subMenu.length > 6 }">
                <nuxt-link
                  v-for="(subItem, subIndex) in item.subMenu"
                  :id="`header-${subItem?.name}`"
                  :key="`${index}${subIndex}`"
                  class="sub-menu__item"
                  :class="[subItem?.type, subItem.alias, { active: isActive(subItem) }]"
                  :to="
                    subItem?.alias === 'all'
                      ? `/${item?.activeUrl.split('/')[1]}`
                      : subItem?.alias?.includes(PAGE_URLS.PROMOTION) || item?.tab === 'tab-promotion'
                      ? `${subItem?.alias}`
                      : `/${item?.activeUrl.split('/')[1]}/${subItem?.alias}`
                  "
                  @click.prevent="onClickSubMenu(item, subItem)"
                >
                  <div class="sub-menu__item--content">
                    <BaseImg
                      class="icon-active"
                      :relative-src="subItem?.icon?.inactive ?? subItem.icon"
                      :alt="subItem.display_name"
                    />
                    <div class="text">
                      <p>{{ subItem.display_name }}</p>
                      <div v-if="(subItem.jackpot || 0) > 0" class="jackpot">
                        <AnimateCountUp class="value" :number="subItem.jackpot" :show-coin="true" is-rolling />
                      </div>
                    </div>
                  </div>
                  <div v-if="!subItem?.isNotShowLine" class="line" />
                </nuxt-link>
              </div>
            </div>
          </nuxt-link>
        </div>
      </div>
      <div class="header-button">
        <BaseImg
          id="search"
          class="header-button__search"
          relative-src="/assets/images/icon/icon-search-history.svg"
          alt="search"
          @click.prevent="openModalOne(MODAL.SEARCH)"
        />
        <div id="notification-header" class="header-button__notif">
          <BaseImg
            id="bell"
            class="header-button__notif--bell"
            relative-src="/assets/images/components/desktop/icon-notif.svg"
            alt="bell"
          />
        </div>
        <UserLogged v-if="currentUser" />
        <UserNotLogin v-if="!currentUser" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { MENUS_DEFAULT } from '~~/constants/menu'
import BaseImg from '~/components/common/base-img.vue'
import { MODAL } from '~/constants/modal'
import { useAppStore } from '@/store/app'
import { getMenus } from '~/constants/menu'
import AnimateCountUp from '~/components/common/animate-count-up.vue'
import { IMenuHeader, ISubMenuHeader } from '~/types/menu.type'
import { useJackpot } from '~/composables/game/useJackpot'
import { useGameStore } from '~~/store/game'
import { PAGE_URLS } from '~/config/page-url'
import { useGame } from '~/composables/game/useGame'
import { useCasino } from '~/composables/casino/useCasino'
const UserNotLogin = defineAsyncComponent(() => import('@/components/desktop/header/user-not-login.vue'))
const UserLogged = defineAsyncComponent(() => import('@/components/desktop/header/user-logged.vue'))

const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { sumJackpot, currentUser } = storeToRefs(store)
const storeGame = useGameStore($pinia)
const jackpotNumber: Ref<number | null> = ref(null)
const { onSumJackpotInterval, onSumJackpotIntervalClear } = useJackpot()
const { providerGames, providerCasino } = storeToRefs(storeGame)
const { MENUS } = getMenus()
const openSubMenu = ref('')
const jsStatus = ref(false)
const { fetchCongGame } = useGame()
const { fetchGameCasino } = useCasino()
const route = useRoute()
const router = useRouter()
const { fetchListBank } = useListBank()
const { openModalOne } = useModal()
const { isLogged } = storeToRefs(store)
const onMouseOver = (item: IMenuHeader) => {
  if (item.subMenu && item.subMenu.length) {
    if (item?.name != null) {
      openSubMenu.value = item.name
    }
  }
}
const onMouseLeave = () => {
  openSubMenu.value = ''
}

const { data: gameData } = useAsyncData(() => {
  const promises = []
  if (providerGames.value?.length === 0) {
    promises.push(fetchCongGame())
  }
  if (providerCasino.value?.length === 0) {
    promises.push(fetchGameCasino())
  }

  return Promise.allSettled(promises)
})

const onClickMenu = (item: IMenuHeader) => {
  openSubMenu.value = ''
  router.push(item.activeUrl)
}

const isActive = (subItem: ISubMenuHeader) => {
  return (
    subItem.alias === route.params.type ||
    (`${PAGE_URLS.CASINO}/${subItem?.alias?.toLowerCase()}` === '/livecasino/all' && route.path === '/livecasino') ||
    subItem.url === route.fullPath
  )
}
const onHideMenu = () => {
  openSubMenu.value = ''
}
function onClickSubMenu(item: IMenuHeader, subItem: ISubMenuHeader) {
  openSubMenu.value = ''
  const mainUrl = item.activeUrl.split('/')[1]
  const subItemUrl = subItem?.alias

  if (subItemUrl?.includes(PAGE_URLS.PROMOTION)) {
    return router.push(subItemUrl)
  }

  if (subItemUrl === 'all') {
    return router.push(`/${mainUrl}`)
  }

  if (item.tab === 'tab-promotion') {
    return router.push(`/${mainUrl}`)
  }

  const link = `/${mainUrl}/${subItemUrl}`
  router.push(link)
}
onMounted(async () => {
  jsStatus.value = true
  await fetchListBank()
})
onMounted(() => {
  onSumJackpotInterval()
  jackpotNumber.value = sumJackpot.value?.jackpotNohu ?? null
})
onBeforeUnmount(() => {
  onSumJackpotIntervalClear()
})

watch(isLogged, () => {
  fetchListBank()
})

const menuListDefault = reactive(MENUS_DEFAULT)
const menuList = reactive(MENUS)

const getSumJackpot = (aliasMenu: string) => {
  switch (aliasMenu) {
    case 'no-hu': {
      return sumJackpot.value.jackpotNohu
    }
    case 'ban-ca': {
      return sumJackpot.value.jackpotFishing
    }
    case 'table-game': {
      return sumJackpot.value.jackpotIngame
    }
    default: {
      return 0
    }
  }
}
watchEffect(() => {
  const updateSubMenu = (submenu: ISubMenuHeader[], type: string) => {
    const targetItem = menuList.find((item) => item.type === type)
    if (targetItem && submenu) {
      targetItem.subMenu = submenu.map((item) => {
        return {
          ...item,
          type,
          jackpot: getSumJackpot(item.alias)
        }
      })
    }
  }
  if (gameData.value) {
    const subMenusToUpdate = [
      { submenu: providerGames.value, type: 'games' },
      { submenu: providerCasino.value, type: 'livecasino' }
    ]
    subMenusToUpdate.forEach(({ submenu, type }) => updateSubMenu(submenu, type))
  }
})
</script>

<style scoped lang="scss" src="public/assets/scss/components/desktop/header/index.scss"></style>
