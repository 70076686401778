<template>
  <div class="top-bet">
    <SectionTitle :title="TITLE_SECTION.TOP_PLAY" />
    <div class="top-bet__container">
      <template v-for="(item, index) in gameList" :key="index">
        <CommonGamesItemGame class="item-game" :item="item" show-coin />
      </template>
    </div>
  </div>
</template>
<script setup lang="ts">
import queryString from 'query-string'
import CommonGamesItemGame from '~/components/common/game-item.vue'
import { useGame } from '~~/composables/game/useGame'
import { TITLE_SECTION } from '~/constants/home'
const SectionTitle = defineAsyncComponent(() => import('@/components/common/section-title.vue'))
const { fetchGames } = useGame()
const fetchTopBetGames = async () => {
  try {
    const queryUrl = queryString.stringify({
      path_alias: 'all',
      provider_code: '',
      sort_by: 'TOP_USER_BET',
      limit: 6,
      page: 1
    })

    const { data: response } = await fetchGames(queryUrl)

    if (response.status === 'OK') {
      return response.data?.items // Return the items directly
    }

    return [] // Return an empty array if the status is not OK
  } catch (error) {
    console.error('fetchTopBetGames', error)
    return [] // Return an empty array on error
  }
}

// Use useAsyncData to fetch the data
const { data: gameList } = await useAsyncData('topBetGames', fetchTopBetGames)
</script>
<style lang="scss" scoped src="public/assets/scss/components/desktop/pages/home/top-bet/index.scss"></style>
