<template>
  <section id="games" class="section-game-mb">
    <SectionTitle :title="TITLE_SECTION.GAME" is-see-more :link="LOBBY_GAME_URLS.QUAY_SO" />
    <div class="section-game-mb__wrapper">
      <div :class="`section-game-mb__content ${isLoading ? 'loading' : ''}`">
        <div v-if="listGames?.length !== 0" class="section-game-mb__content--list">
          <CommonGamesItemGame
            v-for="(item, index) in listGames.slice(0, 3)"
            :key="index"
            :item="item"
            class="mobile"
            :show-coin="false"
            :radius="'4'"
            :hidden-jac-pot="true"
          />
        </div>
        <CommonBaseEmpty
          v-else-if="loadPage && listGames?.length === 0"
          :empty-data="EMPTY_CONTENT[0]"
          :class="'mobile'"
        />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import queryString from 'query-string'
import CommonBaseEmpty from '~/components/common/base-empty'
import CommonGamesItemGame from '~/components/common/game-item.vue'
import { TITLE_SECTION } from '@/constants/home'
import { EMPTY_CONTENT } from '@/constants/games/lobby'
import { useGame } from '~/composables/game/useGame'
import SectionTitle from '~/components/mobile/common/section-title.vue'
import { LOBBY_GAME_URLS } from '~/config/page-url'

const emit = defineEmits(['onLoadingGame'])

const { fetchGames } = useGame()
const isLoading = ref(true)

const fetchGameQuaySo = async () => {
  try {
    isLoading.value = true
    const queryUrl = queryString.stringify({
      path_alias: 'quay-so',
      provider_code: '',
      limit: 3,
      page: 1
    })
    const { data: response } = await fetchGames(queryUrl)
    if (response.status === 'OK') {
      return response.data?.items
    }
    return []
  } catch (error) {
    console.error('fetchTopBetGames', error)
    return []
  } finally {
    isLoading.value = false
  }
}

watch(isLoading, () => {
  emit('onLoadingGame', isLoading.value)
})

const loadPage = ref(false)
setTimeout(() => {
  loadPage.value = true
}, 500)
const { data: listGames } = await useAsyncData('fetchGameQuaySozz', fetchGameQuaySo)
</script>

<style scoped lang="scss" src="public/assets/scss/components/mobile/pages/home/section-quay-so/index.scss"></style>
